.body {
  position: absolute;
  height: 450vh;
  width: 100vw;
}

#content {
  position: absolute;
  height: 450vh;
  width: 100%;
  left: 0;
  top: 0;
  font-family: 'Century Gothic';
  overflow: hidden;
}

#intro {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: black;
  color: white;
}

#whoAmI {
  padding-top: 10vh;
}

#name {
  font-size: calc(10px + 10vmin);
  margin: 0;
}

#webDeveloper {
  width: calc(10px + 80vmin);
  margin: 0;
  margin-top: 5%;
  font-size: calc(10px + 2vmin);
  background-color: white;
  color: black;
}

#skills {
  margin-bottom: 20vh;
  text-align: left;
  font-size: calc(10px + 2vmin);
}

#skills>p {
  margin: 0;
}

.bold {
  font-weight: 700;
  color: lightblue;
}

.skillDetails {
  font-family: 'Ebrima';
  font-size: calc(10px + 2vmin);
  font-weight: 100;
}

#waveBorder {
  position: fixed;
  bottom: 0%;
}

#afterWave {
  position: fixed;
  height: 110vh;
  width: 100vw;
  background-color: white;
}

#projects {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100vh;
  height: 420vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.hidden {
  opacity: 0;
}

#SpreadsheetPanel {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SpreadsheetPanel {
  transform: translate(-30%, -50%);
  transition: all 2s ease;
}

.SpreadsheetPanel.reveal {
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1;
}

.SpreadsheetPanel.hide {
  transform: translate(-70%, -50%);
  z-index: -1;
}

#SpreadsheetPanel__contents {
  display: flex;
  flex-direction: column;
  border: 4px solid black;
  border-radius: 40px;
  overflow: hidden;
  z-index: 3;
  font-size: calc(10px + 2vmin);
}

#SpreadsheetPanelName {
  text-align: center;
  font-size: calc(10px + 4.4vmin);
  font-weight: 700;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0 5% 2% 5%;
}

.detailRow {
  display: flex;
  font-family: 'Ebrima';
}

.detailRow>p {
  margin: 0;
}

.rowNum {
  width: 5vw;
  padding: 0 1%;
  background-color: lightgray;
  border-top: 1px solid black;
  border-right: 1px solid black;
  text-align: right;
}

.rowNum.last {
  padding-bottom: 5%;
}

.rowData {
  border-top: 1px solid black;
  width: 75vw;
}

.bulletRow {
  margin: 0;
  width: 75vw;
  padding: 0 2vw 1vw 2vw;
  font-family: 'Ebrima';
}

#Intellisheets {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Intellisheets {
  transform: translate(-30%, -50%);
  transition: all 2s ease;
}

.Intellisheets.reveal {
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1;
}

.Intellisheets.hide {
  transform: translate(-70%, -50%);
  z-index: -1;
}

#Intellisheets__contents {
  padding: 2% 2% 4% 2%;
  display: flex;
  flex-direction: column;
  border: 4px solid #C5E0B4;
  border-radius: 40px;
  overflow: hidden;
  z-index: 3;
  font-size: calc(10px + 2vmin);
}

#tulip {
  height: 7vh;
  width: auto;
}

#IntellisheetsName {
  color: gray;
  font-size: calc(10px + 4.4vmin);
  margin: 0;
  padding: 1% 5% 2% 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Datafolio {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Datafolio {
  transform: translate(-30%, -50%);
  transition: all 2s ease;
}

.Datafolio.reveal {
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1;
}

.Datafolio.hide {
  transform: translate(-70%, -50%);
  z-index: -1;
}

#Datafolio__contents {
  padding: 2% 2% 4% 2%;
  display: flex;
  flex-direction: column;
  border: 4px solid #7030A0;
  border-radius: 45px;
  overflow: hidden;
  z-index: 3;
  font-size: calc(10px + 2vmin);
}

#DatafolioName {
  text-align: center;
  margin: 0;
  font-size: calc(10px + 8.8vmin);
}

#WanderlustCafe {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#WanderlustCafe__contents {
  padding: 2% 2% 4% 2%;
  display: flex;
  flex-direction: column;
  border: 4px solid #C55A11;
  border-radius: 45px;
  overflow: hidden;
  z-index: 3;
  font-size: calc(10px + 2vmin);
}

.WanderlustCafe  {
  transform: translate(-30%, -50%);
  transition: all 2s ease;
}

.WanderlustCafe.reveal {
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1;
}

.WanderlustCafe.hide {
  transform: translate(-70%, -50%);
  z-index: -1;
}

#WanderlustCafeName {
  text-align: center;
  margin: 0;
  font-size: calc(10px + 8.8vmin);
  color: #C45911;
}

#contact {
  position: absolute;
  width: 100%;
  left: 0;
  top: 420vh;
  
  display: flex;
  flex-direction: column;
  
  color: white;
  font-size: calc(10px + 3vmin);
  z-index: 3;
}

#contactPanel{
  padding: 0 6% 20% 6%;
  background-color: #b6dbe8;
}

.contactEntry {
  display: flex;
}

.contactEntry>p {
  margin: 0;
}

.contactHeader {
  color: black;
  padding-right: calc(1vmin);
}